var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "page-accueil" }, [
    _c("div", { staticClass: "container" }, [
      _c("div", { staticClass: "presentation card" }, [
        _c("h1", [_vm._v(_vm._s(_vm.$t("home.title")))]),
        !_vm.isAuthenticated
          ? _c(
              "div",
              [
                _c(
                  "i18n",
                  { attrs: { path: "home.need_login", tag: "p" } },
                  [
                    _c(
                      "router-link",
                      {
                        attrs: {
                          exact: "",
                          to: { name: "login" },
                          place: "link",
                        },
                      },
                      [_vm._v(" " + _vm._s(_vm.$t("home.link_connect")) + " ")]
                    ),
                  ],
                  1
                ),
              ],
              1
            )
          : _vm._e(),
        _vm.isAuthenticated && _vm.currentUser
          ? _c("div", [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.$t("home.profile_info", {
                      username: _vm.currentUser.nom,
                    })
                  ) +
                  " "
              ),
            ])
          : _vm._e(),
        _vm.isValidated
          ? _c("p", { staticClass: "warning" }, [
              _vm._v(" " + _vm._s(_vm.$t("home.is_validated")) + " "),
            ])
          : _c(
              "p",
              [
                _c(
                  "router-link",
                  {
                    staticClass: "nav-link",
                    attrs: { "active-class": "active", to: { name: "expos" } },
                  },
                  [
                    _c("i", { staticClass: "ion-compose" }),
                    _vm._v(_vm._s(_vm.$t("home.link_to_shows")) + " "),
                  ]
                ),
              ],
              1
            ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }